<template>
    <div>
        <b-card class="bg-transparent mb-0">
            <h1>Autorizações CreditCorp</h1>
            <h4>Condições Gerais para Permissão de  Acesso ao Sistema de Informações de Créditos (SCR)</h4>
        </b-card>
        <b-card class="p-3 terms-fs">
            <p>
                Prezado  Cliente,
            </p>
            <p>
                A  Creditcorp Serviços Corporativos S.A.(“Creditcorp”), empresa inscrita no CNPJ/ME sob o  número  28.132.420/0001-40,
                temcomo  objetivo  o  desenvolvimento,  gerenciamento e administração de uma plataformaeletrônica disponibilizada por
                meio da internet, que possibilita  a  originação  de   empréstimos  para  pessoas  jurídicas  (“Clientes”),  de  forma
                facilitada,  ágil  e  comsegurança,  a  serem  concedidos  por  instituições  financeiras  ou equiparadas(“Empréstimos”).
            </p>
            <p>
                Para  a  realização  de  uma  melhor  análise  de  crédito,  com  o  objetivo  de  viabilizar  aconcessão dos Empréstimos,
                a Creditcorp contrata prestadores de serviços quepossuem ferramentas  adequadas  para  consulta  de  informações  cadastrais
                e financeiras  dos Clientes, sem custo adicional.
            </p>
            <p>
                Dentre  os  prestadores  de  serviços  contratados,  LINKAPITAL  TECNOLOGIA  E  SERVIÇOS FINANCEIROS  LTDA,  inscrita  no
                CNPJ/ME  sob  o  nº  37.380.728/0001-04  (“LinKapital”) possui um aplicativo/software que permite a consulta das informações
                dos Clientes que constem ou venham a constar do Sistema de Informações de Créditos (SCR), gerido pelo Banco  Central  do
                Brasil  (Bacen),  ou  dos  sistemas  que  venham  a  complementá-lo  ou  a substituí-lo, assim como a consulta aos demais
                organizações centralizadoras de cadastros e informações privadas ou governamentais (SERASA, SCPC, Bureau de Cadastros
                Positivos e outros) sobre eventuais débitos.
            </p>
            <p>
                Nossa intenção é sempre facilitar sua vida e da sua empresa. Por isso, solicitamos queo Cliente  avalie  os
                “Termos  e  condições  de  Uso  LinKapital”  para  que  possamos  teracesso  às  suas  informações  cadastrais  e
                financeiras  mediante  utilização  de  certificadodigital.
            </p>
            <p>
                Se  ficar  com  alguma  dúvida,  entre  em  contato  conosco  através  de  qualquer  um  doscanais de comunicação que disponibilizamos.
            </p>
            <p>
                Atenciosamente,
            </p>
            <p>
                Creditcorp Serviços Corporativos S.A.
            </p>
        </b-card>
    </div>
</template>

<script>
import { BCard, BRow, BCol, BImg, BButton, BLink, BIcon } from 'bootstrap-vue'
export default {
    name: 'ScrAuthorizationTerms',
    components: {
        BCard,
        BRow,
        BCol,
        BImg,
        BButton,
        BLink,
        BIcon
    },
    data() {
        return {
            currentDate: new Date().toLocaleDateString('pt-BR', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            })
        }
    }
}
</script>

<style lang="scss">
.card-offert {
    min-height: 15rem !important;
}

.terms-fs {
    font-size: 14px;
    line-height: 21px;
}
</style>